/* eslint-disable no-shadow */
import axios from 'axios'
import {
  getEmailCargaFinalizada,
} from '@/utils/paths'

const pathGetEmailCargaFinalizada = getEmailCargaFinalizada

export function emailEmpleadorResource() {
  function getEnvioEmailEmpleador(empleadorId) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          pathGetEmailCargaFinalizada + empleadorId,
        )
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  return {
    getEnvioEmailEmpleador,
  }
}
