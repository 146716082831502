<template>
  <div>
    <!--layout="horizontal" step-size="xl"-->
    <b-overlay
      :show="isWorking"
      :rounded="overlayConfig.rounded"
      :variant="overlayConfig.variant"
      :opacity="overlayConfig.opacity"
      :blur="overlayConfig.blur"
    >
      <template #overlay>
        <div class="mt-2">
          <div style="text-align: center">
            <span class="spinner-border " />
          </div>
          <div style="text-align: center">
            <small>{{ overlayConfig.message }}</small>
          </div>
        </div>
      </template>
      <form-wizard
        ref="refFormWizard"
        color="#50566E"
        :title="null"
        :subtitle="null"
        :start-index="0"
        finish-button-text="Finalizar"
        back-button-text="Anterior"
        next-button-text="Siguiente"
        :hide-buttons="true"
        layout="vertical"
        transition="fade"
        class="wizard-vertical mb-3"
        @on-complete="formSubmitted"
      >
        <tab-content
          title="Datos generales"
          icon="feather icon-bookmark"
        >
          <datos-generales
            v-if="!isWorking"
            ref="refDatosGenerales"
            :is-first-step="true"
            :is-wizard="true"
            :is-last-step="false"
            :open-mode="openMode"
            :empleador-dto="empleadorDto"
            :datos-generales="datosGenerales"
            :id-empleador="idEmpleador"
            @update-empleador="updateEmpleador"
            @next-step="formWizardDatosGeneralesNextStep"
            @previous-step="formWizardPrevioustStep"
          />
        </tab-content>
        <tab-content
          title="Datos contacto"
          icon="feather icon-inbox"
        >
          <datos-contacto
            v-if="!isWorking"
            :is-wizard="true"
            :is-first-step="false"
            :is-last-step="false"
            :open-mode="openMode"
            :id-empleador="idEmpleador"
            :empleador-dto="empleadorDto"
            :datos-contacto="datosContacto"
            @next-step="formWizardDatosContactoNextStep"
            @previous-step="formWizardPrevioustStep"
          />
        </tab-content>
        <tab-content
          title="Domicilios laborales"
          icon="feather icon-home"
        >
          <domicilio-laboral-lista
            v-if="!isWorking"
            :is-wizard="true"
            :is-first-step="false"
            :is-last-step="false"
            :open-mode="openMode"
            :id-empleador="idEmpleador"
            :empleador-dto="empleadorDto"
            @next-step="formWizardDomicilioLaboralNextStep"
            @previous-step="formWizardPrevioustStep"
          />
        </tab-content>
        <tab-content
          title="Documentación"
          icon="feather icon-file-text"
        >
          <documentacion-empleador
            :is-wizard="true"
            :is-first-step="false"
            :is-last-step="true"
            :open-mode="openMode"
            :id-empleador="idEmpleador"
            :empleador-dto="empleadorDto"
            @next-step="formWizardDocumentacionNextStep"
            @previous-step="formWizardPrevioustStep"
          />
        </tab-content>
      </form-wizard>
    </b-overlay>
  </div>
</template>
<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { empleadoresResource } from '@/services/empleadoresResource'
import { emailEmpleadorResource } from '@/services/emailEmpleadorResource'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DatosGenerales from './DatosGenerales.vue'
import DatosContacto from './DatosContacto.vue'
import DomicilioLaboralLista from './DomicilioLaboralLista.vue'
import DocumentacionEmpleador from './DocumentacionEmpleador.vue'

export default {
  childInterface: {
    getAll: () => { },
  },
  components: {
    FormWizard,
    TabContent,
    DatosGenerales,
    DatosContacto,
    DomicilioLaboralLista,
    DocumentacionEmpleador,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  props: {
    idEmpleador: {
      type: String,
      required: false,
      default: undefined,
    },
    openMode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      titulo: '',
      title: '',
      isWorking: false,
      tabIndex: null,
      overlayConfig: {
        rounded: 'sm',
        variant: 'transparent',
        opacity: '0.5',
        blur: '5px',
        message: '',
      },
      datosGenerales: {
        cuit: null,
        razonSocial: null,
        nombreFantasia: null,
        fechaCarga: null,
        empleadorCategoria: {
          id: null,
          nombre: null,
        },
        empleadorEstado: {
          id: null,
          nombre: null,
        },
        logo: null,
        etapaCompletadaDatosGenerales: false,
      },
      datosContacto: {
        personaContacto: null,
        telefono: null,
        email: null,
        domicilioCalle: null,
        domicilioNumero: null,
        domicilioOficinaDepto: null,
        codigoPostal: null,
        localidad: null,
        provincia: null,
      },
      empleadorDto: {
        id: null,
        cuit: null,
        razonSocial: null,
        nombreFantasia: null,
        empleadorCategoria: {
          id: null,
          nombre: null,
        },
        empleadorEstado: {
          id: null,
          nombre: null,
        },
        fechaCarga: null,
        logo: null,
        etapaDatosGeneralesCompletada: false,
        personaContacto: null,
        telefono: null,
        email: null,
        domicilioCalle: null,
        domicilioNumero: null,
        domicilioOficinaDepto: null,
        codigoPostal: null,
        localidad: {
          id: null,
          nombre: null,
        },
        provincia: {
          id: null,
          nombre: null,
        },
        etapaDatosContactosCompletada: false,
        etapaDomicilioLaboralCompletada: false,
        domicilioLaboral: [],
        documentos: [],
      },
    }
  },
  computed: {
    empleadorAsArray() {
      const arrEmpleador = []
      arrEmpleador.push(this.empleadorDto)
      return arrEmpleador
    },
    currentTab() {
      return this.$refs.refFormWizard
    },
  },
  async created() {
    if (this.idEmpleador !== undefined) {
      await this.findEmpleador(this.idEmpleador)
    }
    this.setTabsLogic()
    this.mounted()
  },
  methods: {
    setTabsLogic() {
      if (this.empleadorDto.etapaDomicilioLaboralCompletada !== null && this.empleadorDto.etapaDomicilioLaboralCompletada === true) {
        this.$refs.refFormWizard.activateTabAndCheckStep(3)
        this.$refs.refFormWizard.navigateToTab(3)
      } else if (this.empleadorDto.etapaDatosContactosCompletada !== null && this.empleadorDto.etapaDatosContactosCompletada === true) {
        this.$refs.refFormWizard.activateTabAndCheckStep(2)
        this.$refs.refFormWizard.navigateToTab(2)
      } else if (this.empleadorDto.etapaDatosGeneralesCompletada !== null && this.empleadorDto.etapaDatosGeneralesCompletada === true) {
        this.$refs.refFormWizard.activateTabAndCheckStep(1)
        this.$refs.refFormWizard.navigateToTab(1)
      }
    },
    updateEmpleador(resultEmpleador) {
      // this.empleadorDto = resultEmpleadorDto
      this.findEmpleador(resultEmpleador.id)
    },
    findEmpleador(idEmpleador) {
      this.isWorking = true
      let promise = null
      this.overlayConfig.message = 'Recuperando datos de usuario'
      if (idEmpleador !== undefined) {
        promise = empleadoresResource().findByIdEmpleador(idEmpleador)
          .then(resultsFindByidEmpleadores => {
            this.empleadorDto = { ...resultsFindByidEmpleadores }
            this.actualizarDatosGenerales()
            this.actualizarDatosContacto()
          }).catch(error => {
            console.error(error)
          })
          .finally(() => {
            this.isWorking = false
          })
      }
      return promise
    },
    actualizarDatosGenerales() {
      this.isWorking = true
      this.datosGenerales.cuit = this.empleadorDto.cuit
      this.datosGenerales.razonSocial = this.empleadorDto.razonSocial
      this.datosGenerales.nombreFantasia = this.empleadorDto.nombreFantasia
      this.datosGenerales.empleadorCategoria.id = this.empleadorDto.empleadorCategoria.id
      this.datosGenerales.empleadorEstado.id = this.empleadorDto.empleadorEstado.id
      this.datosGenerales.logo = this.empleadorDto.logo
      this.datosGenerales.etapaCompletadaDatosGenerales = this.empleadorDto.etapaDatosGeneralesCompletada
      this.datosGenerales.fechaCarga = this.empleadorDto.fechaCarga
      this.isWorking = false
    },
    actualizarDatosContacto() {
      this.isWorking = true
      this.datosContacto.personaContacto = this.empleadorDto.personaContacto
      this.datosContacto.telefono = this.empleadorDto.telefono
      this.datosContacto.email = this.empleadorDto.email
      this.datosContacto.domicilioCalle = this.empleadorDto.domicilioCalle
      this.datosContacto.domicilioNumero = this.empleadorDto.domicilioNumero
      this.datosContacto.domicilioOficinaDepto = this.empleadorDto.domicilioOficinaDepto
      this.datosContacto.codigoPostal = this.empleadorDto.codigoPostal
      this.datosContacto.localidad = {
        id: this.empleadorDto.localidad.id,
        nombre: this.empleadorDto.localidad.nombre,
      }
      this.datosContacto.provincia = {
        id: this.empleadorDto.provincia.id,
        nombre: this.empleadorDto.provincia.nombre,
      }
      this.isWorking = false
    },
    formWizardDatosGeneralesNextStep(datosGeneralesDto, id) {
      this.empleadorDto.id = id
      this.empleadorDto.cuit = datosGeneralesDto.cuit
      this.empleadorDto.nombreFantasia = datosGeneralesDto.nombreFantasia
      this.empleadorDto.razonSocial = datosGeneralesDto.razonSocial
      this.empleadorDto.empleadorCategoria.id = datosGeneralesDto.empleadorCategoria.id
      this.empleadorDto.empleadorEstado.id = datosGeneralesDto.empleadorEstado.id
      this.empleadorDto.etapaDatosGeneralesCompletada = datosGeneralesDto.etapaDatosGeneralesCompletada
      this.empleadorDto.fechaCarga = datosGeneralesDto.fechaCarga
      this.empleadorDto.logo = datosGeneralesDto.logo
      this.empleadorDto.etapaDatosGeneralesCompletada = datosGeneralesDto.etapaDatosGeneralesCompletada
      // this.$refs.refFormWizard.nextTab()
      this.next()
    },
    formWizardDatosContactoNextStep(datosContactoDto) {
      this.empleadorDto.personaContacto = datosContactoDto.personaContacto
      this.empleadorDto.telefono = datosContactoDto.telefono
      this.empleadorDto.email = datosContactoDto.email
      this.empleadorDto.domicilioCalle = datosContactoDto.domicilioCalle
      this.empleadorDto.domicilioNumero = datosContactoDto.domicilioNumero
      this.empleadorDto.domicilioOficinaDepto = datosContactoDto.domicilioOficinaDepto
      this.empleadorDto.codigoPostal = datosContactoDto.codigoPostal
      this.empleadorDto.localidad.id = datosContactoDto.localidad.id
      this.empleadorDto.provincia.id = datosContactoDto.provincia.id
      this.empleadorDto.provincia.nombre = datosContactoDto.provincia.nombre
      // this.$refs.refFormWizard.nextTab()
      this.next()
    },
    formWizardDomicilioLaboralNextStep(domicilioLaboral) {
      // Revisión, se está pisando domicilio laboral con la respuesta del back que trae de todo y no solo domiciollaboral.
      // Esto que hay que rehacerlo.
      this.empleadorDto.domicilioLaboral = domicilioLaboral
      // this.$refs.refFormWizard.nextTab()
      this.next()
    },
    formWizardDocumentacionNextStep(documentacion) {
      this.empleadorDto.documentos = documentacion
      // this.$refs.refFormWizard.nextTab()
      this.next()
    },
    next() {
      return this.$refs.refFormWizard.isLastStep === true ? this.formSubmitted() : this.$refs.refFormWizard.nextTab()
    },
    formWizardPrevioustStep() {
      this.$refs.refFormWizard.prevTab()
    },
    formSubmitted() {
      this.envioEmailCargaFinalizada()
      this.$router.push('/empleadores')
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Carga completada',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
    },
    envioEmailCargaFinalizada() {
      this.isWorking = true
      emailEmpleadorResource().getEnvioEmailEmpleador(this.empleadorDto.id)
        .then(() => {
        })
        .catch(error => {
          console.error('error:', error)
        })
        .finally(() => {
          this.isWorking = false
        })
    },
    mounted() {
      if (this.empleadorDto.id) {
        this.titulo = 'Ver Empleador'
      } else {
        this.titulo = 'Nuevo Empleador'
      }
    },
  },
}
</script>
