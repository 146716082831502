<template>
  <b-row>
    <b-col cols="12">
      <wizard-vue-form
        :id-empleador="idEmpleador"
        :open-mode="openMode"
      />
    </b-col>

  </b-row>
</template>
<script>
import WizardVueForm from './WizardVueForm.vue'

export default {
  components: {
    WizardVueForm,
  },
  props: {
    idEmpleador: {
      type: String,
      required: false,
      default: undefined,
    },
    openMode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      title: '',
      isWorking: false,
    }
  },
  computed: {},
  created() { },
  methods: {},
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
